@import "../../node_modules/@crossroads/ui-components/src/ui-components/Dialogue.scss";
@import "theme/ui-components/_variables.scss";

$icon-size: 18px;
$header-height: 62px;

.block {
  margin-left: size("gutter");
  margin-right: size("gutter");
  padding: size("gutter");
  border-radius: size("border-radius");
  overflow: hidden;
  color: color("text");
  z-index: 1;

  .leave & {
    animation: fadeOut forwards transition("duration--organisms") transition("ease-ease");
  }
}

.body {
  color: color("text--dark");
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: $header-height;

  margin: -#{size("gutter")};
  margin-bottom: size("gutter--small");
  padding: 0 size("gutter");;

  border-bottom: 1px solid color("border--muted");
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  color: color("text");
  font-size: size("font");
  padding-right: size("gutter");

  // offset uneven font
  top: 2px;
}

.actions {
  button {
    text-transform: none !important;
    color: color("primary") !important;
    &:hover {
      text-decoration: underline !important;
      background: none !important;
    }
  }

  .secondary {
    color: color("secondary") !important;
  }
}
