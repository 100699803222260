/*
  Styles for the category navigation cards
*/

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$str: .05;
$maxItems: 10;

$iconHeight: 1.5rem;
$padding: 1rem;
$padding--small: .25rem;

$breakpoint: "medium";

.list {
  @media (min-width: breakpoint($breakpoint)) {
    display: flex;
  }
}

.item {
  display: flex;
  flex: 1 1 100%;

  @for $i from 1 to $maxItems {
    &:nth-last-child(#{$i}n + #{$i}) {
      background-color: rgba(color("accent"), 1 - ($str * $i));
    }
  }

  @media (max-width: breakpoint($breakpoint) - 1px) {
    & + & {
      margin-top: 1px;
    }
  }
}

.link {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: $padding;
  color: #fff;

  @media (max-width: breakpoint("mini") - 1px) {
    font-size: size("font--small");
  }

  @media (min-width: breakpoint($breakpoint)) {
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    font-size: size("font--tiny");
    padding-left:  $padding--small;
    padding-right: $padding--small;
  }

  &:focus,
  &:hover {
    background-color: #2b91a3;
    text-decoration: none;
  }

  &:active:hover {
    opacity: 1;
    color: rgba(#fff, .5);
  }
}

.icon {
  flex: 0 0 auto;
  width: auto;

  @media (max-width: breakpoint($breakpoint) - 1px) {
    height: 1.25rem;
    margin-right: $padding;
  }

  @media (min-width: breakpoint($breakpoint)) {
    height: $iconHeight;
    margin-bottom: $padding;
  }
}
