@use "sass:math";

@mixin columns($columns, $gutter: size("grid-gutter")) {
  float: left;
  width: math.div(100%, $columns);

  @if ($gutter) {
    padding-left:  $gutter * 0.5;
    padding-right: $gutter * 0.5;
  }

  &:nth-child(#{$columns}n + #{$columns}) {
    float: right;
  }
}

@mixin span($col-width, $grid-size: 12, $gutter: size("grid-gutter"), $last: false) {
  float: if($last, right, left);
  width: math.div(100%, math.div($grid-size, $col-width));

  @if ($gutter) {
    padding-left:  $gutter * 0.5;
    padding-right: $gutter * 0.5;
  }
}

@mixin row($padding: $gutter * 0.5) {
  display: flex;
  flex-wrap: wrap;
  padding: 0 $padding;
  @include clearfix;
  @include stretch;
}

@mixin stretch($distance: size("grid-gutter") * 0.5) {
  left: -#{$distance};
  width: calc(100% + #{$distance * 2});
  max-width: none;

  :global(.no-csscalc) & {
    left: auto;
  }
}

$opposites: (
  'top':    'bottom',
  'right':  'left',
  'bottom': 'top',
  'left':   'right',
);

@mixin triangle($direction: top, $length: 1em, $color: #fff) {
  $active-sides: map-keys(map-remove($opposites, $direction));
  $opposite: map-get($opposites, $direction);

  content: '';
  display: block;

  width:  0;
  height: 0;

  position: absolute;
  #{$opposite}: 100%;
  margin-#{$opposite}: -1px;

  border: $length solid transparent;
  border-#{$direction}: 0;
  border-#{$opposite}-color: $color;
}


@mixin clearfix {
  &:after {
    content: '';
    clear: both;
    display: table;
  }
}

@mixin commonTransition {
  transition-property: color, background, border-color, opacity, transform, box-shadow, outline;
  transition-duration: transition("duration--molecules");

  &:active:hover {
    transition: none;
  }
}

%commonTransition {
  @include commonTransition();
}

@mixin momentumScroll {
  overflow-x: hidden;
  overflow-y: auto;

  :global([data-whatintent='touch']) & {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

@mixin momentumScrollX {
  overflow-y: hidden;
  overflow-x: auto;

  :global([data-whatintent='touch']) & {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

@mixin resetInput {
  border:     none;
  outline:    none !important;
  background: none;
  box-shadow: none;
  appearance: none;

  line-height:   1;
  border-radius: 0;
  padding:       0;
  margin:        0;

  cursor: text;
  color: inherit;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(#fff, 0);

  &:disabled {
    cursor: not-allowed;
  }
}

%resetInput {
  @include resetInput();
}

@mixin vertical-margins($margin) {
  margin-top:    $margin;
  margin-bottom: $margin;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin placeholderStyles {
  &::-webkit-input-placeholder { @content; }
  &::-moz-placeholder          { @content; }
  &:-ms-input-placeholder      { @content; }
}

@mixin fontSmoothing {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
  -moz-font-feature-settings: "liga" on;
}

@mixin separator($distance: size("gutter"), $directions: top, $border-style: 1px solid color("border")) {
  @each $direction in $directions {
    padding-#{$direction}: $distance;
    margin-#{$direction}:  $distance;

    @if ($border-style != null) {
      border-#{$direction}: $border-style;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(#000, nth(.12 .16 .19 .25 .30, $depth));

  @return 0 (-$primary-offset) $blur $color;
}



/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(#000, nth(.24 .23 .23 .22 .22, $depth));

  @return 0 $primary-offset $blur $color;
}




// Gives a card depth effect.
// @param {Number} $depth - depth level (between 1 and 5)
// @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
// @requires {function} top-shadow
// @requires {function} bottom-shadow
@mixin drop-shadow($depth: 1) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `item`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}

