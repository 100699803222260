@import "theme/ui-components/_variables.scss";

$icon-size--large: 16px;
$icon-size--small: 9px;

.block {
  text-align: center;

  &.size__large {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.size__small {
    display: inline-block;

    span {
      min-width: 5ch;
      display: inline-block;
    }
  }
}

.increment, .decrement {
  padding: 0;
  border: none;
  text-align: center;

  .size__large & {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid color("accent");
    padding: size("gutter--mini");
    color: color("accent");
  }

  .size__small & {
    display: inline-block;
    padding: 0 size("gutter--mini");
  }

  background: transparent;
  flex: 0 0 auto;

  svg {
    width: $icon-size--large;
    height: $icon-size--large;

    .size__small & {
      width: $icon-size--small;
      height: $icon-size--small;
    }
  }

  &:disabled, &:disabled:hover {
    border-color: color("border");
    color: color("border");
    cursor: not-allowed;
  }
  &:hover {
    opacity: .8;
    //background: color("background--hover");
  }
  &:active {
    opacity: .5;
  }
}
