@use "sass:math";

@import "../../node_modules/@crossroads/ui-components/src/ui-components/ActiveFilters.scss";
@import "theme/ui-components/_variables.scss";

$pill-height: 31px;
$lvl1bg: color("off-canvas-menu");
$lvl2bg: color($lvl1bg, -40%);

.activeFilterEmpty {
}

.activeFilters {
  padding: 0 size("gutter--large");

  &Top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -#{$guttterActiveFiltersMobile};
    margin-top: 0.2rem !important;
    width: calc(100% + #{$guttterActiveFiltersMobile * 2});

    @media (min-width: breakpoint("tiny")) {
      margin: 0 -#{$guttterActiveFiltersDesktop};
      width: calc(100% + #{$guttterActiveFiltersDesktop * 2});
    }
  }
}

.activeFiltersLeft {
  margin-left: -#{size("gutter--mini")};
  padding: size("gutter--mini") 0;
}

.activeFilter {
  padding: size("gutter--mini") size("gutter--large");
  margin: size("gutter--mini") * 0.5 0;
  margin-left: size("gutter--mini");

  flex-direction: row-reverse;
  align-items: center;
  background: color("border--muted");
  border-radius: $pill-height;
  height: $pill-height;
  padding: 0 size("gutter--mini");
  padding-right: math.div(size("gutter--mini"), 1.5);

  &:hover {
    background: #fff;

    .icon {
      background: color("negative");
    }
  }

  .text {
    margin-left: 0;
    margin-right: 8px;
    font-size: size("font--mini");
  }

  .icon {
    border-radius: 50%;
    background: color("text");
    svg {
      top: -2px;
      left: 0px;
      width: 15px;
      height: 15px;
    }
  }
}

.clearbuttonDesktop, .clearbuttonMobile {
  color: #fff !important;
}

.clearbuttonMobile {
  background: color("primary");
}
