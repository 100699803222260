@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  display: flex;
  flex-direction: column;
}

.row {
  & + & {
    margin-top: 4.5rem;

    @media (min-width: breakpoint("medium")) {
      margin-top: 6.5rem;
    }
  }
}


.topWrapper {
  .intro {
    padding: 1.5rem 1rem 1.5rem 0;
    font-size: size("font--small");
    max-width: 34rem;
    text-align: center;
    margin: 0 auto;
  }

  @media (max-width: breakpoint("small") - 1px) {
    padding: 0;
  }

  @media (max-width: breakpoint("medium") - 1px) {
    .intro {
      display: none;
    }
  }
}

.previews {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 4rem;
}


.categories {
  @media (max-width: breakpoint("medium") - 1px) {
    order: 999;
  }
}
