
@import "../../node_modules/@crossroads/shop-views/src/shop-views/DiscountCode";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  .boxHeader {
    font-size: size("font--small") !important;
    font-weight: 600;
  }
}

.discountCode {
  gap: size("gutter--small") !important;

  input {
    font-size: size("font--small") !important;
  }

  button {
    padding: 0 size("gutter--small") !important;
    font-size: size("font--small") !important;
  }
}

@media (min-width: breakpoint("medium")) and (max-width: breakpoint("large")) {
  .discountCode {
    flex-direction: column !important;

    button {
      width: 100% !important;
    }
  }
}