@import "theme/ui-components/_variables.scss";

$padding: size("gutter--large");

.block {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $padding;
  padding-right: $padding;
  flex: 0 1 auto;

  @media (max-width: breakpoint("tiny") - 1px) {
    padding-left: size("gutter--small");
    padding-right: size("gutter--small");
  }

  @media (min-width: breakpoint("small")) {
    &__small {
      width: breakpoint("small") !important;
    }
  }

  @media (min-width: breakpoint("medium")) {
    width: breakpoint("medium");

    &__medium {
      width: breakpoint("medium") !important;
    }
  }

  @media (min-width: breakpoint("large")) {
    width: breakpoint("large");
  }
}
