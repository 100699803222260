@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

// local variables:
// ----------------

  $background     : white;
  $triangleLength : 10px;
  $spacing        : 2px;
  $triangleInset  : size("gutter--mini");

// ----------------


.block {
  padding: size("gutter--mini");
  position: absolute;
  z-index: 2;
  border-radius: size("border-radius");
  background-color: $background;
  filter: drop-shadow(bottom-shadow(1));

  &::before {
    content: "";
  }
}

.top {
  bottom: 100%;
  margin-bottom: $spacing;

  &::before {
    @include triangle(bottom, $triangleLength, $background);
  }
}

.right {
  right: 0;

  &::before {
    right: $triangleInset;
  }
}

.bottom {
  top: 100%;
  margin-top: $spacing;

  &::before {
    @include triangle(top, $triangleLength, $background);
  }
}

.left {
  left: 0;

  &::before {
    left: $triangleInset;
  }
}
