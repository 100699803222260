@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$transition: transition("duration--organisms");

:export {
  transition: strip-unit($transition);
}

@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.block {
  backface-visibility: hidden;

  @media (max-width: breakpoints("tiny")) {
    width: 100%;
    left: 0;
  }
  pointer-events: none;

  z-index: z-index("overlay");

  .messages {
    display: flex;
    flex-direction: column;
  }

  .message {
    width: 100%;
  }
}

.wrapper {
  @media (max-width: breakpoints("tiny")) {
    width: 100%;
    padding: 0;
  }

  & > p {
    margin-bottom: 0;
  }
}

.list {
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: breakpoints("tiny")) {
    top: size("gutter");
    right: size("gutter--large");
    align-items: flex-end;
  }
}

.itemInner {
  background: #fff;
  padding: size("gutter--small");
  cursor: pointer;

  p {
    margin-top: 0;
    max-width: 90%;
  }

  .success & {
    background: color("positive");
    color: #fff;
  }

  .error & {
    background: color("negative");
    color: #fff;
  }
}

.icon {
  width: 17px;
  height: 17px;
  top: -20px;
  left: -2px;
  float: right;
}

.dim {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: z-index("overlay");

  background: rgba(0, 0, 0, 0.2);
  height: 100vh;

  transition: opacity $transition transition("ease-in");
  opacity: 0;

  .appearing & {
    opacity: 1;
  }

  .leaving & {
    opacity: 0 !important;
  }
}
