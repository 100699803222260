@font-face {
  font-family: "Brix Sans";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/EON_BrixSansWeb-Regular.woff2")  format("woff2"),
       url("/assets/fonts/EON_BrixSansWeb-Regular.woff")   format("woff"),
       url("/assets/fonts/EON_BrixSansWeb-Regular.ttf")    format("truetype");
}

@font-face {
  font-family: "Brix Sans";
  font-weight: 400;
  font-style: italic;
  src: url("/assets/fonts/EON_BrixSansWeb-RegularItalic.woff2")  format("woff2"),
       url("/assets/fonts/EON_BrixSansWeb-RegularItalic.woff")   format("woff"),
       url("/assets/fonts/EON_BrixSansWeb-RegularItalic.ttf")    format("truetype");
}

@font-face {
  font-family: "Brix Sans";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/EON_BrixSansWeb-Medium.woff2")  format("woff2"),
       url("/assets/fonts/EON_BrixSansWeb-Medium.woff")   format("woff"),
       url("/assets/fonts/EON_BrixSansWeb-Medium.ttf")    format("truetype");
}

@font-face {
  font-family: "Brix Sans";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/EON_BrixSansWeb-Bold.woff2")  format("woff2"),
       url("/assets/fonts/EON_BrixSansWeb-Bold.woff")   format("woff"),
       url("/assets/fonts/EON_BrixSansWeb-Bold.ttf")    format("truetype");
}

@font-face {
  font-family: "Brix Sans";
  font-weight: 900;
  font-style: normal;
  src: url("/assets/fonts/EON_BrixSansWeb-Black.woff2")  format("woff2"),
       url("/assets/fonts/EON_BrixSansWeb-Black.woff")   format("woff"),
       url("/assets/fonts/EON_BrixSansWeb-Black.ttf")    format("truetype");
}

// dummy selector to prevent the fonts to be stripped
.a {
  font-family: "Brix Sans";
}

