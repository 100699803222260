/*
  Link + Button component.
*/

@import "theme/ui-components/_variables.scss";

$padding: 1rem;
$inset: 1rem;
$iconLength: .8rem;
$buttonWidth: 3rem;

$scaleColor: 5%;

$lvl1bg: color("off-canvas-menu");
$lvl2bg: color($lvl1bg, -40%);
$lvl3bg: color($lvl1bg, -80%);


.inset {
  padding-right: $buttonWidth;
}

.link {
  padding: $padding !important;
  padding-left: size("gutter") !important;
}

.clickable {
  display: block;
  width: 100%;
  text-align: left;
  padding: $padding ($padding + $inset);
  background-color: $lvl1bg;
  border-color: $lvl2bg;
  border-top-width: 1px;
  border-top-style: solid;
  color: #fff !important;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: breakpoints("tiny") - 1px) {
    padding-left: $padding !important;
  }

  &.isActive {
    &::before {
      content: "";
      width: .3rem;
      height: .3rem;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: calc(50% - .15rem);
      left: #{size("gutter") * .5 - .15rem};
    }
  }

  &:hover:not(:active),
  &:focus:not(:active) {
    :global([data-whatintent="mouse"]) & {
      background-color: color($lvl1bg, $scaleColor);
    }
  }

  &:active:hover {
    opacity: 1;

    :global([data-whatintent="mouse"]) & {
      color: rgba(white, .5);
    }
  }

  &First {
    border-top-color: $lvl2bg !important;
  }

  &Two {
    padding-left: size("gutter") + $inset !important;
    background-color: $lvl2bg;
    border-color: $lvl1bg;

    &:hover:not(:active),
    &:focus:not(:active) {
      :global([data-whatintent="mouse"]) & {
        background-color: color($lvl2bg, $scaleColor);
      }
    }
  }

  &Three {
    padding-left: size("gutter") + $inset * 2 !important;
    background-color: $lvl3bg;
    border-color: $lvl1bg;

    &:hover:not(:active),
    &:focus:not(:active) {
      :global([data-whatintent="mouse"]) & {
        background-color: color($lvl3bg, $scaleColor);
      }
    }
  }
}

.button {
  padding: 0 $padding;
  width: $buttonWidth;
  background: none;
  text-align: center;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  border-right: 0;
  border-bottom: 0;
  border-width: 1px;
}

.icon {
  width: $iconLength;
}
