@import "../../node_modules/@crossroads/ui-components/src/ui-components/Slider.scss";
@import "theme/ui-components/_variables.scss";

$handle-border-color: #fff;
$handle-border-color-active: #fff;
$handle-bg-color: color("primary");
$track-color: rgba(#fff, 0.08);
$value-color: color("primary");

$transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$transition-duration: 200ms;

$handle-size: 28px;
$handle-size-hover: 32px;
$top-offset: 3px;
$top-offset-hover: 1px;
$tracker-height: 7px;

$handle-size--small: 18px;
$handle-size-hover--small: 24px;
$top-offset--small: 9px;
$top-offset-hover--small: 3px;
$border-width: 3px;
$border-width--small: 2px;
$tracker-height--small: 2px;
$transition-duration-handles: 50ms;

.block {
  height: $handle-size-hover + ($top-offset-hover * 2) !important;

  .track {
    background: $track-color;
  }

  .track, .value {
    top: calc(50% - #{$tracker-height * 0.5});
    height: $tracker-height;
    border-radius: $tracker-height;
  }

  .handle {
    background: $handle-bg-color;
    border-color: $handle-border-color;
    border-width: $border-width;
    width: $handle-size !important;
    height: $handle-size !important;
    padding: 0;
    top: $top-offset !important;
    box-shadow: none !important;

    transition: border $transition-duration $transition-timing-function;
    will-change: border;
    transform: translateZ(0);

    &:hover, &:active, &:focus {
      outline: none;
      border-color: $handle-border-color-active;
    }

    &:before {
      display: block;
      content: "";
      height: 10px;
      width: 10px;
      left: 6px;
      top: 0px;
      border-radius: 50%;
      background: #fff;
    }
  }

  .value {
    background: $value-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.hasVariant {
    &.small {
      height: $handle-size-hover--small + ($top-offset-hover--small * 2);

      & .track, & .value {
        top: 50%;
        height: $tracker-height--small;
      }

      & .handle {
        top: $top-offset--small !important;
        width: $handle-size--small !important;
        height: $handle-size--small !important;
        padding: 0;
        border-width: 2px;

        &:before {
          height: 6px;
          width: 6px;
          left: 4px;
          top: 0px;
        }

        &:hover, &:active, &:focus {
          outline: none;
        }
      }
    }
  }

};
