@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$border-size: 3px;

@keyframes pulse {
  0% { transform: scale(1); }
  40% { transform: scale(1.03); }
  100% { transform: scale(1); }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cardValue {
  display: flex;
  flex-wrap: wrap;
  @include stretch(size("gutter--mini") * 0.25);

  .col {
    padding: size("gutter--mini") * 0.25;
    opacity: 0;
    animation: fadeIn transition("duration--organisms") transition("ease-ease") forwards;
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 3px;
    border: $border-size solid transparent;

    transition: border transition("duration--molecules") transition("ease-in");

    padding: $border-size;
    &.active {
      border-color: color("primary");
    }

    &:hover:not(.active) {
      opacity: 0.7;
    }

    &.outOfStock {
      // @TODO: show out of stock in other way. Maybe red cross over
    }

    @media (max-width: breakpoint("small") - 1px) {
      font-size: size("font--medium");
    }
  }

  .button.active .outline {
    opacity: 1;
  }

  .outOfStockLabel {
    position: absolute;
    bottom: 0;
    right: -0;

    z-index: 1;

    font-size: size("font--small");
    color: color("text--muted");
    padding: size("gutter--mini") * 0.5;
  }
}

.color {
  $size: 35px;

  width: $size;
  height: $size;
  background: #eee;

  border: 1px solid color("text--muted");
  border-radius: 2px;
}

.colorTitle {
  display: block;
  margin-bottom: size("gutter--mini");

  &Key {
    color: color("text--muted");
  }
}
