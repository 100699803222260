/*
  Visually indicate how close the user is to free shipping.
*/

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$barHeight: 5px;

.block {
  width: 10rem;
}

.bar {
  overflow: hidden;
  height: $barHeight;
  background: rgba(#fff, .5);
  top: .2rem;
}

.progress {
  width: 100%;
  background: #fff;
  transition: transform transition("duration--long");
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
}

.text {
  margin: .8rem 0 0 0;
  font-size: size("font--small");
  font-style: italic;
  line-height: 1;
}
