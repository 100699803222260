@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$icon-size: 25px;
$iconMarginX: size("gutter--small");
$inputHeight: 13rem;

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.block {
  width: 100%;
  animation: slideDown .4s cubic-bezier(.1,1,.55,1) forwards;
}

.container {
  padding: 0;
}

.input {
  @extend %resetInput;

  width: 100%;
  background-color: white;
  text-align: center;

  height: $inputHeight;
  background: #fff;
  border-bottom: size("ui-border") solid color("accent");
  font-size: 2em !important;
}

.label {
  text-align: center;
  pointer-events: none;
  font-size: size("font--small");
  color: color("text--muted");
  position: absolute;
  right: 0;
  left: 0;
  bottom: size("gutter");
}
