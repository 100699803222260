@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

@keyframes blink {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

$size: 11px;

.block {
  display: flex;
}

.dot {
  display: block;
  width: $size;
  height: $size;
  border-radius: 50%;
  background: color("text--muted");

  & + & {
    margin-left: size("gutter--mini") * 0.5;
  }

  animation: blink 1000ms transition("ease-in") alternate;
  animation-iteration-count: infinite;
}
