@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$img-size: 64px;

@keyframes appear {
  0% { opacity: 0; transform: translateY(100%); }
  100% { opacity: 1; transform: translateY(0); }
}

.block {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z-index("popup");

  opacity: 0;
  animation: appear transition("duration--templates") transition("ease-out") forwards;
  animation-delay: transition("duration--molecules");
  display: flex;
  flex-direction: column;

  table {
    width: 100%;
  }

  td {
    vertical-align: top;

    &:last-child {
      text-align: right;
      line-height: 1.3;
    }
  }
}

.drawer {
  background-color: color("box--dark");
  color: #fff;
  padding: size("gutter") 0;

  animation: appear transition("duration--templates") transition("ease-out") forwards;

  h4 {
    font-size: size("font--large");
  }
}

.drawerInner {
  flex: 1;
  overflow-y: auto;

  // Should make it reach the exact middle of the screen on large breakpoints.
  max-width: calc(#{floor(breakpoint("large") * 0.5)} - #{size("grid-gutter") * 2});
  @include separator(size("gutter--small"), top, 2px solid #fff);
}

.items {
  width: 100%;
  font-size: size("font--small");
}

.item {
  &.processing {
    cursor: not-allowed;
    opacity: .5;
  }

  button {
    color: #fff;
  }

  td {
    padding: size("gutter--mini") 0;
  }

  &Remove {
    button {
      text-decoration: underline;
    }
  }

  &Qty {
    @media(max-width: breakpoint("tiny") - 1px) {
      display: none;
    }
  }
}

.totals {
  display: flex;
  justify-content: space-between;
  font-size: size("font");
  font-weight: 700;
  padding: size("gutter--small") 0 #{size("gutter--small") * 0.5} 0;
}

.cart {
  background: color("accent");
  color: #fff;
  box-shadow: top-shadow(2);

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: breakpoint("small") - 1px) {
      padding-left: 0 !important;
      padding-right: size("gutter--tiny") !important;
    }
  }

  &__left {
    display: flex;
  }

  &__right {
    display: flex;
  }
}

.toggleButton {
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  padding-left: size("gutter") !important;
  padding-right: size("gutter") !important;
  font-size: size("font--small");
  min-width: 12em;

  @media (max-width: breakpoint("small") - 1px) {
    padding-left: size("gutter--mini") * 1.1 !important;
    padding-right: size("gutter--mini") * 1.1 !important;
    min-width: 0;

    svg {
      width: 17px;
      height: 17px;
      margin-right: size("gutter--mini") !important;
    }
  }

  svg {
    transition: transform transition("duration--molecules") transition("ease-ease");
    margin-right: size("gutter--small");
    transform: scaleY(-1) rotate(90deg);

    .open & {
      transform: scaleY(1) rotate(90deg);
    }
  }
}

.subTotal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 size("gutter");
  font-size: size("font--small");

  & > *:first-child {
    font-weight: 700;
    margin-bottom: size("gutter--mini");
  }

  @media (max-width: breakpoint("tiny") - 1px) {
    display: none;
  }
}

.freeShippingMeter {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: size("gutter--large");

  @media (max-width: breakpoint("medium") - 1px) {
    display: none;
  }
}

.toCheckout {
  margin: size("gutter--tiny") 0 !important;

  @media (max-width: breakpoint("small") - 1px) {
    font-size: size("font--small");
    padding: 0 size("gutter--tiny") !important;
  }
}
