@import "../../node_modules/@crossroads/ui-components/src/ui-components/ProductViewMedia.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$item-width: 76px;
$closeButtonLength: 20px;
$closeButtonPadding: .7rem;
$closeButtonTop: 1.8rem;
$closeButtonRight: 1.8rem;
$image-max-width: 800px; // The product image size on the server is 800x80

.gallery {
  &Item {
    max-width: calc(#{$item-width} + #{size("grid-gutter")});
    img {
      border: 1px solid color("border--muted");
      border-radius: size("border-radius");
    }
  }
}

.lightbox {
  &Image {
    max-width: $image-max-width;
  }

  &Gallery {
    &Item {
      width: $item-width;
      height: $item-width;
    }
  }

  &Close {
    position: fixed;
    top: $closeButtonTop;
    right: $closeButtonRight;
    width: calc(#{$closeButtonLength} + #{$closeButtonPadding * 2});
    height: calc(#{$closeButtonLength} + #{$closeButtonPadding * 2});
    padding: $closeButtonPadding;
    background: rgba(255, 255, 255, .7);


    path {
      fill: color("primary") !important;
    }
  }

  .galleryItem {
    width: $item-width;
  }
}
