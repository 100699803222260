@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.registerForm {
  padding: size("gutter");
}

.form {
  max-width: 620px;
  margin: 0 auto;
  margin-top: size("gutter");
  padding-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    display: flex;
    button {
      border-radius: 0 size("border-radius") size("border-radius") 0!important ;
    }
  }
  @media (max-width: breakpoint("small") - 1px) {
    button {
      margin-top: size("gutter--small");
    }
  }

  & > *:first-child {
    flex: 1 0 auto;
  }
}

.paragraph {
  color:#fff;
}

.input {
  input {
    color: color("text");

    @media (min-width: breakpoint("small")) {
      border: none !important;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}

.button {
  border-width: 2px !important;
  text-transform: uppercase;
  font-weight: 800 !important;

  @media (min-width: breakpoint("small")) {
    height: auto !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-right: 10px !important;
  }

  @media (max-width: breakpoint("small") - 1px) {
    margin-top: size("gutter") !important;
    width: 100%;
  }
}
