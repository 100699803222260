@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.price, .msrp {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:first-child {
    font-weight: bold;
  }
}

.price {
  color: color("primary");
}

.msrp {
  color: color("text");
  padding-top: size("gutter--mini");
}

.link{
  font-weight: bolder;
}
