@import "theme/ui-components/_variables.scss";

.adventCalendar {
    background-size: cover;
    text-align: center;
    font-weight: 500;
    background-position: center 20%;

    @media (max-width: 440px) {
      background-position: center;
    }

    &Doors {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: size("gutter--large");

        @media (max-width: (breakpoints("small") - 1px)) {
          flex-direction: column;
          align-items: center;
        }
    }

    &Title {
        text-align: center;
        display: block;

        @media (max-width: breakpoints("small")) {
          font-size: size("font--large");
        }
        @media (max-width: (breakpoints("mini") + 10px)) {
          margin-left: -#{size("gutter--small")};
          margin-right: -#{size("gutter--small")};
        }
      }

    h1 {
    margin-bottom: size("gutter--small");
    }

}

.christmas {
  padding: (size("gutter--huge") * 2) 0;

  .adventCalendarTitle {
    color: #fff;
  }
}

.easter {
  background: #fffece;
  padding: (size("gutter--huge")) 0;

  .adventCalendarTitle {
    color: #c27507;
  }
}
