@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$body-height: 307px;
$imageWidthIncrease: 10px;
$borderStyle: 1px solid color("border");
$padding: 1rem;
$bgColor: color("box");


:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  overflow: hidden;
  text-decoration: none;
  transition: border-color transition("duration--molecules") transition("ease-ease"),
              boxh-shadow transition("duration--molecules") transition("ease-ease");

  border: $borderStyle;
}

.header {
  overflow: hidden;
  background: #fff;
}

.imageLink {
  display: block;
  transition: opacity transition("duration--molecules") transition("ease-ease");

  &:active:hover {
    opacity: 0.5;
  }
}

.imageWrapper {
  transition: transform transition("duration--molecules") transition("ease-ease");
}

.image {
  position: absolute;
  width: 100%;
}

.body {
  flex: 1 0 auto;
  display: flex;
  min-height: $body-height;
  flex-direction: column;
  background: $bgColor;
  border-top: $borderStyle;
  padding: size("gutter--small");
  text-align: left;
}

.innerBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.flexStart {
  justify-content: flex-start;
}

.brandRow {
  display: flex;
  justify-content: space-between;

  margin-top: size("gutter--mini") * 0.5;
  margin-bottom: size("gutter--mini") * 0.5;

  font-size: size("font--small");
}

.brand {
  color: color("text--muted");
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  padding-right: size("gutter--mini");
}

.name, .brand {
  font-size: size("font--small");
}

.name {
  font-weight: 500;
  color: color("text");
  font-size: size("font--medium");
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  line-height: font("line-height");
  width: calc(100% - #{size("gutter") * 2});


  @media (min-width: breakpoint("small")) {
    width: calc(100% - #{size("gutter") * 1.5});
  }

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--small");
  }
}

.brand {
  line-height: 1;
}

.description {
  overflow: hidden;
  height: 2em * font("line-height");
  line-height: font("line-height");
  font-size: size("font--small");
  color: color("text--muted");

  &::after {
    content: "";
    display: block;
    width: 8ch;
    height: 1em * font("line-height");
    background-image: linear-gradient(to left, rgba($bgColor, 1), rgba($bgColor, 0));
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.price {
  font-size: size("font--small");
  line-height: 1;
}

.colors {
  pointer-events: none;
  display: flex;
  justify-content: center;
  position: absolute;
  top: size("gutter--mini");
  z-index: 1;
  left: 0;
  right: 0;

  & > div {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    margin: 0 size("gutter--mini") * 0.5;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: -#{size("gutter--mini")};

  & > * {
    flex: 1 0 50%;
    padding: size("gutter--mini");

    button {
      width: 100%;
    }
  }

  button {
    font-size: size("font--small") !important;
    padding: 0 size("gutter--small") !important;
    white-space: nowrap;
  }
}

.dummy {
  .image {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
  }

  .body {
    background: none;
    border-top: none;
  }

  span, p {
    display: block;
  }

  .name {
    max-width: 50%;
  }

  .brand {
    max-width: 53%;
  }

  .price {
    max-width: 42%;
  }
}
