@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$headerHeight: size("header-height");
$submitButtonHeight: 72px;
$closeButtonSideLength: 20px;
$closeButtonOffsetRight: -8px;
$lvl1bg: color("off-canvas-menu");
$lvl2bg: color($lvl1bg, -40%);

.header {
  flex: 0 0 auto;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $lvl1bg;
  color: #fff;
  padding: size("gutter") size("gutter--large");

  & > h2 {
    margin: 0;
    font-size: size("font--medium");
  }

  .closeButton {
    width: $closeButtonSideLength;
    height: $closeButtonSideLength;
    right: $closeButtonOffsetRight;

    & > svg {
      width: 100%;
      height: 100%;
      path {
        fill: #fff;
      }
    }
  }
}

.activeFilters {
  border-top: 1px solid $lvl2bg;
  border-bottom: 1px solid $lvl2bg;
}

.body {
  height: calc(100vh - #{$headerHeight});
  max-height: calc(100vh - #{$headerHeight});
  min-height: 0;

  .top {
    height: calc(100% - #{$submitButtonHeight});
    max-height: calc(100% - #{$submitButtonHeight});
    overflow-y: auto;
  }

  .bottom {
    height: $submitButtonHeight;

    button.submit_button {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}

.filters {
  flex: 1;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.filterMenu {
  border-top: none;
  width: 100%;
}

.value {
  padding: size("gutter") size("gutter--large");
  border-top: 1px solid $lvl2bg;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &:hover {
    background-color: $lvl2bg !important;
  }

  &:first-child {
    border-top: none;
  }

  &.active {
    background-color: $lvl2bg !important;
    color: #fff !important;
  }
}

button.submit_button {
  width: 100%;
  height: 100%;
  border-radius: 0;

  &:hover:active {
    background: color("accent") !important;
    opacity: 0.8;
  }
  &:focus {
    background: color("accent") !important;
  }
}
