@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$blockHeight: 350px;
$blockHeightSmall: 220px;
$imageOffSetSmall: -50px;

.block {
  background: color("body");
  overflow: hidden;
  padding-bottom: size("gutter");
}

.wrapper {
  height: 100%;
  max-height: 100%;
}

.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .totalCount {
    align-self: flex-start;
    font-size: size("font--small");
    font-weight: bold;
  }

  @media(max-width: breakpoint("small") - 1px) {
    align-items: center;

    & > div, & > div > p {
      text-align: center;
    }
  }
}
