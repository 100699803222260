@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
}

.body {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  padding: size("gutter--huge") 0;
  text-align: center;
}

.heading {
  @include separator(size("gutter"), bottom);
}

.text {
  margin: 0;
}
