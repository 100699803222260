@import "../../node_modules/@crossroads/ui-components/src/ui-components/Dropdown.scss";
@import "theme/ui-components/_variables.scss";

$item-height: 42px;
$countryDropDownHeight: 2.5rem;
$countryDropDownInset: .5rem;

.block {
  width: 100%;
}

.head, .body {
  border: 2px solid #757575;
}

.headInner {
  border-right: 0;
}

.blockOpen .body {
  margin-top: 0;
  border-top: 0;
}

.chevronArea {
  width: $item-height;
  justify-content: center;
  padding: 0;
}

.headInner, .item, .chevronArea {
  display: flex;
  align-items: center;

  height: $item-height;
  padding-top: 0;
  padding-bottom: 0;
}

.item {
  height: 44px;
  color: color("text");

  &.active {
    background-color: inherit;

    &:after {
      content: "";
      position: absolute;
      right: size("gutter--mini");
      width: 20px;
      height: 20px;
      background: url("/assets/icons/tick.svg") no-repeat center;
      background-size: 100%;
    }
  }

  &:hover {
    text-decoration: underline;
    background: color("body--off");
  }
}

.hasVariant {
  &.countryDropdown {
    &.block {
      height: $countryDropDownHeight;
    }

    .head {
      height: 100%;
      border: 1px solid color("border");
      border-radius: size("border-radius");

      [data-error="true"] & {
        border-color: color("negative");
        background-color: #fbeeee;
      }

      &Inner {
        height: 100%;
        padding-top: 1.2rem;
        padding-left: $countryDropDownInset;
        padding-right: $countryDropDownInset;
        font-size: size("font");
        background-color: transparent;

        &:focus {
          border-color: transparent;
          outline: none;
        }
      }
    }

    .body {
      max-height: 300px;
      overflow-y: auto;
      border: 1px solid color("checkout-fg");
      font-size: size("font--small");
    }

    &.blockDown .body {
      box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
    }

    &.blockUp .body {
      box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.25);
    }

    .item {
      background-color: #fff;
    }

    .searchIconArea {
      background-color: transparent;
    }
  }

  &.dropdownSort {
    &.block {
      width: 150px;
      border: none;
    }

    .head {
      border: none;
      height: 23px;

      &Inner {
        height: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .chevronArea {
      width: 23px;
      height: auto;
    }

    .body {
      border: 1px solid lightgrey;
      border-radius: size("border-radius");
      font-size: size("font--small");
    }

    .item {
      font-size: size("font--small");

      &.active {
        &:after {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
