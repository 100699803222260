@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$animationTimingFunction: transition("ease-ease");
$animationDuration: transition("duration--templates");

:export {
  animationDuration: strip-unit($animationDuration);
}

@keyframes dimEntering {
  0% { opacity: 0;

  }
  100% { opacity: 0.3; }
}

@keyframes dimLeaving {
  0% { opacity: 0.3; }

  100% { opacity: 0; }
}

@keyframes containerInitial {
  0% { opacity: 0 }
  99% { opacity: 0 }
  100% { opacity: 1 }
}

.container {
  position: fixed;
  z-index: 1;
  // height: 100vh;
  max-height: 100vh;
  top: 0;

  bottom: 0;
  width: 500px;
  z-index: z-index("system") + 2;
  display: flex;
  flex-direction: column;
  background: color("off-canvas-menu");
  color: #fff;
  transition: transform  $animationDuration $animationTimingFunction;
  transform: translateX(0);
  animation: containerInitial $animationDuration linear forwards;
  overflow-y: auto;

  @media (max-width: #{breakpoint("tiny") - 1px}) {
    width: 100vw;
  }

  &.left {
    left: 0;

    &.hidden {
      transform: translateX(-100%);
    }
  }

  &.right {
    right: 0;

    &.hidden {
      transform: translateX(100%);
    }
  }
}

.dim {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system") + 1;
  opacity: 0;
  will-change: opacity transform;

  &.hidden {
    opacity: 0;
    display: none;
  }

  &.entered {
    opacity: 0.3;
    background: #000;
  }

  &.hiding {
    animation: dimLeaving $animationDuration $animationTimingFunction forwards;
    background: #000;
  }

  &.entering {
    animation: dimEntering $animationDuration $animationTimingFunction forwards;
    background: #000;
  }
}


