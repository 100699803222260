@import "theme/ui-components/_variables.scss";

.adventCalendarHero {
  background-size: cover;
  text-align: center;
  height: 480px;
  background-position: center;

  .top {
    background-color: color("hero--background");
  }
}

.breadcrumbs {
  top: size("header-margin");
  z-index: z-index("popup");
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  color:#fff;
  h1 {
    margin-bottom: 0;
  }
  p {
    font-weight: 500;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin: size("gutter") 0;
  > div {
    padding: 0;
  }
  form {
    margin:0;
    button {
      border-radius: 0 size("border-radius") size("border-radius") 0!important;
    }
  }
  input {
    border: 1px solid color("accent")!important;
  }
  @media (max-width: (breakpoint("small") - 1px)) {
    justify-content: center;
    form {
      display: none;
    }
  }
}

.totalCountWrapper {
  margin-top: size("gutter--large");
  margin-bottom: size("gutter--small");
}

.totalCount {
  font-size: size("font--medium");
}

