@import "../../node_modules/@crossroads/ui-components/src/ui-components/OffCanvasFilterMenu.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$headerHeight: size("header-height");
$submitButtonHeight: 72px;
$closeButtonSideLength: 20px;
$closeButtonOffsetRight: -8px;
$lvl1bg: color("off-canvas-menu");
$lvl2bg: color($lvl1bg, -40%);

.header {
  flex: 0 0 auto;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: size("gutter") size("gutter--large");
  background: color("off-canvas-menu");
  color: #fff;

  & > h2 {
    font-size: size("font--medium");
    margin: 0;
  }

  .closeButton {
    width: $closeButtonSideLength;
    height: $closeButtonSideLength;
    right: $closeButtonOffsetRight;

    & > svg {
      width: 30px;
      height: 30px;
      path {
        fill: #fff;
      }
    }
  }
}

.activeFilters {
  border-top: 1px solid $lvl2bg;
  border-bottom: 1px solid $lvl2bg;
}

button.submit_button {
  width: 100%;
  height: 100%;
  border-radius: 0;

  &:hover:active {
    background: color("accent") !important;
    opacity: 0.8;
  }
  &:focus {
    background: color("accent") !important;
  }
}

