@import "theme/ui-components/_variables.scss";

$view-transition-ease: transition("ease-ease");
$view-transition-duration: transition("duration--slow");

:export {
  viewDuration: $view-transition-duration;
}

.block {
  display: flex;
  flex: 1 0 100%;
  max-width: 100%;
  background: #fff;
  flex-direction: column;
  min-height: 100vh;
}

.top {
  flex: 1 0 auto;
  padding-bottom: size("gutter--large") * 3;
}

.footer {
  flex: 0 0 auto;
}

.height {
  height: size("header-height");
  margin-bottom: size("header-margin");
}

.notices {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: z-index("popup") + 1;
  box-shadow: 0 -6px 36px 0 rgba(0, 0, 0, 0.12);

  >div:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }
}

.notificationBar {
  background-color: color("primary--dark");
  color: color("text--light");
  border-color: color("text--light");
}
