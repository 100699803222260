@import "theme/ui-components/_variables.scss";

$searchableDropdownHeight: 2.5rem;
$paddingX: 1rem;
$floatLabelOffset: -.7rem;

.error {
  display: block;
  font-size: size("font--tiny");

  div:focus + & {
    animation: none;
  }
}

.errors {
  color: color("negative");
  font-size: size("font--small");
  padding-top: size("gutter--small");
}

.fieldErrors:not(:focus) {
  background-color: color("negative", 95%) !important;
  border-color: color("negative", 60%) !important;
}

.checkboxMessage {
  left: -.6rem;
  top: 110%;
}

.searchableDropdown {
  height: $searchableDropdownHeight;

  &Label {
    position: absolute;
    color: color("text--muted");
    pointer-events: none;
    left: $paddingX;
    top: 0;
    height: 100%;
    transform: translateY(0);
    transition: transform transition("duration--molecules"), font-size transition("duration--molecules");
    display: flex;
    align-items: center;
  }

  div[data-open=true] + &Label, &Label__active {
    font-size: size("font--mini");;
    transform: translateY($floatLabelOffset);
    font-weight: 400;
  }
}
