@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$animationTimingFunction: transition("ease-ease");
$animationDuration: transition("duration--templates");
$headerHeight: 68px;
$activeFiltersMinHeight: 70px;
$submitButtonHeight: 72px;
$closeButtonSideLength: 36px;
$closeButtonOffsetRight: -8px;
$lvl1bg: color("off-canvas-menu");
$lvl2bg: color($lvl1bg, -40%);
$lvl3bg: color($lvl1bg, -80%);


.block {
  border-top: 1px solid $lvl3bg;
  width: 500px;
  background-color: $lvl2bg;
}
