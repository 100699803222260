@use "sass:math";
@import "../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

$floatLabelOffsetY: -.7rem;
$height: 2.5rem;
$inset: .5rem;
$background         : white;
$transition         : border-color transition("duration--molecules"), color transition("duration--molecules");

.block {
  &.float {
    label {
      text-transform: none;
      color: color("text--muted") !important;
    }

    input:disabled {
      border: 1px solid color("border");
    }
  }
}

.input {
  @extend %resetInput;
  width: 100%;
  height: $height;
  font-size: 20px;
  line-height: $height;
  padding-left:  $inset;
  padding-right: $inset;
  letter-spacing: inherit;
  background-color: $background;
  transition: $transition;
  font-weight: font("weight--bold");
  color: color("text--dark");
  border: 1px solid color("border");
  border-radius: size("border-radius");

  .hasSlotLeft & {
    padding-left: calc(#{$inset * 2} + #{$icon-size});
  }

  .hasSlotRight & {
    padding-right: calc(#{$inset * 2} + #{$icon-size});
  }

  &:enabled {
    &:focus {
      border-color: color("border", -40%);
    }
  }

  .error & {
    border-color: color("negative");
    background: #fbeeee;

    &:enabled:focus {
      border-color: color("negative", -40%);
    }
  }

  &:disabled {
    border: none;
    background-color: $disabledBackground !important;
    color: color("text", 30%) !important;
  }

  .float & {
    padding-top: size("input-padding") * 0.8;
    padding-left:  calc(#{$inset} * 2);
    padding-right: calc(#{$inset} * 2);

    &:disabled + .label {
      color: color("text", 30%) !important;
    }
  }
}

.float .input:focus + .label,
.float:not(.empty) .label {
  font-weight: 400;
  font-size: size("font--mini");
  transform: translateY($floatLabelOffsetY);
}

