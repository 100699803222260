@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$spacing: size("gutter--large") * 3;
$manufacturerLogoWidth: 180px;
$manufacturerLogoHeight: 108px;
$gutterWidth--medium: 4;
$gutterWidth--large:  9;

.split {
  @media (min-width: breakpoint("small")) {
    @include clearfix;
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    max-width: 30rem;
  }

  @media (min-width: breakpoint("medium")) {
    padding-top: size("gutter--large");
  }

  @media (min-width: breakpoint("medium")) and (max-width: (breakpoint("large") - 1px)) {
    @include stretch(size("grid-gutter") * $gutterWidth--medium * 0.5);
  }

  @media (min-width: breakpoint("large")) {
    @include stretch(size("grid-gutter") * $gutterWidth--large * 0.5);
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}

.left, .right {
  @media (max-width: (breakpoint("medium") - 1px)) {
    & + & {
      margin-top: size("gutter");
    }
  }

  @media (min-width: breakpoint("medium")) and (max-width: (breakpoint("large") - 1px)) {
    @include columns(2, size("grid-gutter") * 4);
  }

  @media (min-width: breakpoint("large")) {
    @include columns(2, size("grid-gutter") * 9);
  }
}

.left {
  float: left;
  @media (min-width: breakpoint("small")) {
    position: sticky;
  }
}

.right {
  float: right;
}


.breadcrumbs {
  margin-bottom: size("gutter--large");
}

.header {
  margin-bottom: size("gutter");

  @media(max-width: (breakpoint("medium") - 1px)) {
    text-align: center;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  padding-right: size("gutter--large") * 1.5;
}

.name {
  font-size: size("h2");
  font-weight: 900;
  margin: 0 0 size("gutter");
}

.brand {
  display: inline-block;
  color: color("primary");
  font-size: size("font--medium");
  text-decoration: underline;
}

.goto {
  text-decoration: underline;
}

.qtyPicker,
.addToCart,
.options,
.options > *  {
  @media (max-width: (breakpoint("small") - 1px)) {
    width: 100%;
  }
}

.qtyPicker {
  margin-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    width: 135px;
  }
}

.totalsAndAddToCart {
  z-index: 1;
  margin-top: size("gutter--large");
  text-align: right;

  @media (min-width: breakpoint("small")) {
    float: right;
  }
}

.totals {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: size("gutter--small");

  & > *:first-child {
    font-size: size("font--small");
  }

  & > *:last-child {
    font-weight: 700;
    font-size: 2rem;
    margin-left: size("gutter--tiny");
  }
}

.price {
  display: block;
  margin: size("gutter--small") 0;
  padding: size("gutter") 0;
  border-top: 1px solid color("border");
  border-bottom: 1px solid color("border");
}

.gutter {
  padding-top: size("gutter");
}

.externalShowcaseButton {
  width: 100%;
}

.addToCartButton {
  margin-top: size("gutter") !important;
  margin-bottom: size("gutter") !important;
  width: 100%;
}

.addToCart h3 {
  font-family: #{font("body")};
  font-weight: bold;
  font-size: size("font");
  margin-bottom: size("gutter--mini");
}

.section {
  &Header {
    span {
      font-size: size("font--small");
      margin-top: 0;
      margin-bottom: 0;
    }

    border: none;
    margin-top: size("gutter");
    background: color("box");
    border-radius: size("border-radius");
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: size("gutter--small");
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(255,255,255,0);

    transition-property: color,background,border-color,opacity,transform,box-shadow,outline;
    transition-duration: .2s;

    &:hover {
      color: #fff;
      background: color("accent");
    }

    &:hover:active {
      color: hsla(0,0%,100%,.5);
    }

    svg {
      transition: transform transition("duration--organisms") transition("ease-ease");

      width: 20px;
      height: 20px;

      .section__open & {
        transform: rotate(90deg);
      }
    }
  }

  &Body {
    padding: size("gutter--small");
    font-size: size("font--small");
  }
}

.description {
  color: color("text");

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: size("gutter");
  }

  li + li {
    margin-top: size("gutter--small");
  }

  .manufacturer {
    margin-top: size("gutter--large") * 1.5;

    .logo {
      max-width: $manufacturerLogoWidth;
      max-height: $manufacturerLogoHeight;
      display: inline-block;

      & img {
        max-width: $manufacturerLogoWidth;
        max-height: $manufacturerLogoHeight;
      }
    }
  }
}

.lists {
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }

  padding-top: size("gutter--large") * 2;

  .relatedList {
    width: 100%;

    @media (min-width: breakpoint("small")) {
      padding: 0 size('grid-gutter') * 0.5;
      float: left;
      width: 50%;
    }
  }

  .historyList {
    @media (min-width: breakpoint("small")) {
      padding: 0 size('grid-gutter') * 0.5;
      float: right;
      width: 50%;
    }

    @media (max-width: (breakpoint("small") - 1px)) {
      margin-top: size("gutter--large");
    }
  }
}

.hintWrapper {
  min-height: 100vh;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummyContent {
  .top {
    height: size("gutter--large");
  }

  .middle {
    height: size("gutter--large");
    margin-top: size("gutter--large");
  }

  .bottom {
    margin-top: size("gutter--large");
    height: 200px;
  }

  .top, .middle, .bottom {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }
}

.imageHint {
  width: 100%;
  border-radius: size("border-radius");
}
