@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/shop-views/src/shop-views/SuccessView.scss";

$imageSize: 124px;

.header {
  margin-bottom: size("gutter");

  .title {
    font-size: 2rem;
  
    @media (min-width: breakpoint("small-medium")) {
      font-size: size("h1");
    }
  }

  .checkIconBG {
    display: none;
  }
}

.success {
  flex-direction: column !important;

  @media (min-width: (breakpoint("medium"))) {
    flex-direction: row !important;
  }

  @media (max-width: breakpoint("medium")) {
    .left {
      width: 100% !important;
    }
  
    .right {
      width: 100% !important;
    }
  }
}

.item {
  &Container {
    align-items: center;
  }

  &Image {
    width: $imageSize;
    height: $imageSize;
  }

  @media (max-width: breakpoint("medium")) {

    &Info {
      padding: size("gutter--small");
    }

    &Image {
      width: $imageSize * 0.9;
      height: $imageSize * 0.9;
    }
  }
}