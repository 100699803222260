@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$headerHeight: size("header-height");
$closeButtonSideLength: 20px;
$closeButtonOffsetRight: -8px;
$search-height: 4rem;
$scaleColor: 5%;

$lvl1bg: color("off-canvas-menu");
$lvl2bg: color($lvl1bg, -40%);
$lvl3bg: color($lvl1bg, -80%);

.topMenu {
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
  padding-bottom: size("gutter--huge");
}

.header {
  flex: 0 0 auto;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $lvl1bg;
  color: #fff;
  padding: size("gutter") size("gutter--large");
  border-color: $lvl2bg;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  & > h2 {
    margin: 0;
    font-size: size("font--medium");
  }

  .closeButton {
    width: $closeButtonSideLength;
    height: $closeButtonSideLength;
    right: $closeButtonOffsetRight;

    & > svg {
      width: 100%;
      height: 100%;
      path {
        fill: #fff;
      }
    }
  }
}

.search {
  height: $search-height;
  width: 100%;
  color: rgba(white, .5);

  @include placeholderStyles {
    color: rgba(white, .5);
  }
}

.searchInput {
  background: color("off-canvas-menu");
  color: #fff;
  border: none;
  padding: 0 size("gutter--small") 0 3.5rem;
  width: 100%;
  cursor: text;
  height: $search-height;

  &:focus, &:active {
    outline: 0;
  }

  &:focus {
    &, + .searchButton {
      color: white !important;
    }
  }
}

.searchButton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: size("gutter");
  border: none;
  padding: 0;
  background: none;
  color: rgba(white, .5);
  transition: color transition("duration--molecules") transition("ease-ease");
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: size("gutter--small");
  padding-left: size("gutter");
  color: #fff;
  cursor: pointer;

  background-color: $lvl1bg;
  border-color: $lvl2bg;
  border-top-width: 1px;
  border-top-style: solid;

  &.isActive {
    &::before {
      content: "";
      width: .3rem;
      height: .3rem;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: calc(50% - .15rem);
      left: #{size("gutter") * .5 - .15rem};
    }
  }

  &:hover:not(:active),
  &:focus:not(:active) {
    :global([data-whatintent="mouse"]) & {
      background-color: color($lvl1bg, $scaleColor);
    }
  }

  &:active:hover {
    opacity: 1;

    :global([data-whatintent="mouse"]) & {
      color: rgba(white, .5);
    }
  }

  user-select: none;

  &:hover {
    text-decoration: none;
  }

  span {
    display: flex;
    align-items: center;
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  svg {
    color: #fff;
    width: 18px;
    height: 18px;
  }
}

.chevron {
  width: 24px !important;
  height: 24px !important;
  transform: rotate(-90deg);
  color: #fff !important;
}

.close {
  width: size("mobile-header-icon");
  height: size("mobile-header-icon");
}
