@import "theme/ui-components/_variables.scss";

.block {
  font-size: size("font--small");
  margin-bottom: size("gutter");

  a {
    color: color("accent") !important;
  }
}

.delimeter {
  margin: 0 10px;
  margin-top: 2px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #3f3d3a;
  display: inline-block;
}
