@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$checkmarkMarginRight: .3rem;
$lvl1bg: color("off-canvas-menu");
$lvl2bg: color($lvl1bg, -40%);
$lvl3bg: color($lvl1bg, -80%);

.value {
  background-color: $lvl2bg;
  color: #fff !important;
  padding: size("gutter") size("gutter--large");
  border-top: 1px solid $lvl3bg;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: $lvl3bg;
    color: #fff !important;

    .checkmark {
      fill: #fff;
      stroke: #fff;
    }
  }

  &:first-child {
    border-top: none;
  }

  .checkmark {
    fill: #fff;
    stroke: #fff;
    position: absolute;
    right: calc(#{size("gutter--large")});
  }
}
