@import "../../node_modules/@crossroads/shop-views/src/shop-views/CheckoutCart";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$imageSize: 100px;

.container {
  width: auto !important;
  padding: 0 !important;

  @media (max-width: breakpoint("medium")) {
    &Main {
      display: block !important;
    }

    &Left,
    &Right {
      float: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100% !important;
    }
  }

  .back {
    text-decoration: underline;
    span {
      font-size: size("font") !important;
    }
  }
}

.summary {
  &Title {
    font-size: size("font--small");
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    z-index: 900;

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    clip-path: inset(-15px 0px 0px 0px);

    .box {
      border-radius: 0;
    }
  }
}

.btmPaddingBlock {
  display: block !important;

  @media (min-width: breakpoint("medium")) {
    display: none !important;
  }
}

.chevronCloseIcon {
  width: 24px;
  height: 24px;
}

.item {
  .image {
    width: $imageSize;
    height: $imageSize;
  }

  .name {
    width: 100% !important;
    max-width: 280px !important;
  }

  .right {
    @media (min-width: breakpoint("medium")) {
      flex: 1 1 35%;
      text-align: right;
    }
  }
}

