@use "sass:math";

@import "../../node_modules/@crossroads/shop-views/src/shop-views/DiscountCode";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$btnSize: 42px;

.wrapper {
  border: none !important;
  display: flex !important;
  gap: math.div(size("gutter--mini"), 2);

  input {
    border: none !important;
    text-align: center !important;
    width: 32px;
  }
}

.decrement,
.increment {
  border-radius: 50%;
  border: 2px solid color("accent");
  background-color: color("body");
  height: $btnSize;
  width: $btnSize;

  &:hover,
  &:focus {
    filter: brightness(1.15);
  }
}