@import "../../node_modules/@crossroads/ui-components/src/ui-components/Button.scss";
@import "theme/ui-components/_variables.scss";

$button-height--medium: 50px;

.button {
  border: none;

  &.primary {
    @include commonButton(
      $background: color("accent"),
      $color: #fff,
      $br: size("border-radius"),
    );

    box-shadow: none;
    font-size: size("font");
    // padding: size("padding--tiny") size("gutter--small");

    &:active:hover {
      background-size: inherit !important;
      transition: inherit !important;
    }

    &:disabled {
      background: color("disabled") !important;
      border-color: color("disabled") !important;
      color: rgba(color("text"), .35) !important;
    }
  }

  &.accent {
    @include commonButton(
      $color: white,
      $background: color("accent"),
      $br: size("border-radius"),
    );
    font-size: size("font--medium");
  }

  &.inverted {
    @include commonButton(
      $color: color("primary"),
      $background: #fff,
      $br: size("border-radius"),
    );
    font-size: size("font--medium");
    box-shadow: $shadow;

    &:hover {
      text-decoration: none !important;
    }
  }

  &.inverted__accent {
    @include commonButton(
      $color: color("accent"),
      $background: #fff,
      $br: size("border-radius"),
    );
    font-size: size("font--medium");
    box-shadow: $shadow;
  }

  &.ghost__accent {
    font-size: size("font");
    color: color("accent");
    border: 2px solid color("accent");
    border-radius: size("border-radius");

    &:hover {
      color: color("accent", 5%);
      border-color: color("accent", 5%);
    }

    &:hover:active {
      color: color("accent", 10%);
      border-color: color("accent", 10%);
    }
  }


  &.secondary {
    @include commonButton(
      $background: color("secondary"),
      $color: #fff,
      $br: size("border-radius"),
    );

    transition: background 0.1s;
    font-size: size("font");

    &:active:hover {
      background-size: inherit !important;;
      transition: inherit !important;;
    }
  }

  &.secondarydark {
    @include commonButton(
      $background: #486fe3,
      $color: #fff,
      $br: size("border-radius"),
    );

    transition: background 0.1s;
    font-size: size("font");
  }

  &.blank {
    padding: size("gutter--mini") * 1.1 size("gutter--large");
    color: color("accent");

    &:enabled:active:hover {
      text-decoration: underline;
    }
  }

  &.negative {
    @include commonButton(
      $background: #fff,
      $color: color("accent"),
      $br: size("border-radius"),
    );

    transition: background 0.1s;

    &:enabled {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.ghost {
    font-size: size("font");
    color: color("accent");
    border: 2px solid color("accent");

    &:hover {
      color: color("accent", 10%);
      border-color: color("accent", 10%);
    }
  }
}

.hasVariant {
  &.size {
    &__large {
      padding: size("gutter--mini") * 1.17 size("gutter--large") size("gutter--mini") * 1.1;

    }

    &__medium {
      height: $button-height--medium;
      padding: 0 size("gutter");
    }

    &__small {
      padding: size("gutter--mini") * .65 size("gutter--small") size("gutter--mini") * .5;
      font-size: size("font");
    }
  }

  &.link {
    padding: 0;
    height: auto;
    @extend %commonTransition;
    text-decoration: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(#fff, 0);
    cursor: pointer; /* [4] */
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: color("accent");
    }

    &:focus {
      outline: 0;
      color: color("accent");
    }

    &:active:hover {
      opacity: .5;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }
}


