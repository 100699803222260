/*
  Category subnavigation
*/

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$fadeWidth: 4rem;

.block {
  overflow: hidden;
  left: 0 !important;
  width: auto !important;

  &::before {
    content: "";
    display: block;
    height: size("ui-border");
    background-color: color("border");
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    @include stretch(size("gutter--large"));
  }

  &::after {
    content: "";
    display: block;
    width: $fadeWidth;
    pointer-events: none;
    background-image: linear-gradient(-90deg, rgba(#fff, 1), rgba(#fff, 0));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.list {
  display: flex;
  padding-bottom: size("ui-border");
  @include momentumScrollX;

  @media (max-width: (breakpoint("medium") - 1px)) {
    padding-left:  size("gutter--large");
    padding-right: size("gutter--large");
  }
}

.item {
  float: left;

  & + & {
    margin-left: size("gutter");
  }
}

.link {
  display: block;
  padding: size("gutter--small") 0;
  font-size: size("font--small");
  white-space: nowrap;
  color: color("text");

  &:focus,
  &:hover {
    color: color("primary");
  }

  &.isActive {
    color: color("primary");

    &::before {
      content: "";
      display: block;
      height: size("ui-border");
      background-color: color("primary");
      z-index: 1;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
    }
  }
}

