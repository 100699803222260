@import "../../node_modules/@crossroads/shop-views/src/shop-views/OrderSummary";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  .body {
    tr {
      font-size: size("font--small");
    }
  }
}