@import "../../node_modules/@crossroads/shop-views/src/shop-views/Checkout";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.checkout {
  width: auto!important;
  padding: 0!important;
}

.back {
  text-decoration: underline;
  span {
    font-size: size("font") !important;
  }
}

.modal {
  max-width: calc(#{breakpoint("large")} - 4rem) !important;

  @media (max-width: breakpoint("small")) {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.termsWrapper {

  @media (max-width: breakpoint("small")) {
    max-height: 90vh !important;
    max-width: 100% !important;
  }

  & > div:first-child {
    width: 100% !important;
  }
}