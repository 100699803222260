@import "../../../node_modules/@crossroads/ui-components/src/ui-components/FilterMenu/FilterContainer.scss";
@import "theme/ui-components/_variables.scss";

$headerHeight: 60px;
$closeIconMarginRight: -2px;
$lvl1bg: color("off-canvas-menu");
$lvl2bg: color($lvl1bg, -40%);

.block {
  border-bottom: 1px solid $lvl2bg;

  &__header {
    background: $lvl1bg;

    .open & {
      border-bottom-color: $lvl2bg !important;
    }

    & > h3 {
      font-size: size("font");
      color: #fff;
    }

    .closeIcon {
      transform: rotate(90deg);
      transition: transform transition("duration--organisms") transition("ease-ease");
      margin-right: $closeIconMarginRight;

      .open & {
        transform: rotate(90deg) scaleX(-1);
      }

      path {
        color: #fff !important;
      }
    }
  }
}

