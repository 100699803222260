@import "theme/ui-components/_variables.scss";

// local variables:
// ----------------

$christmasWidth:  150px;
$christmasHeight: 120px;

$easterWidth:  250px;
$easterHeight: 250px;

/*
  Door.scss
  ------------------------------------------------------------

*/

  @keyframes wobble {
    0% {
      transform: none;
    }

    15% {
      transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }

    30% {
      transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }

    45% {
      transform: translate3d(-2.5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }

    60% {
      transform: translate3d(1.25%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }

    75% {
      transform: translate3d(-0.625%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }

    100% {
      transform: none;
    }
  }

// ----------------

.door {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  color: #fff;
  font-size: size("font") * 2;
  font-weight: 100;

  &Open {
    // box-shadow: 0 0 20px 2px rgba(30, 162, 177, 0.6);
    
    img {
      animation: wobble 1500ms infinite;
    } 
  }

  &Today {
    backface-visibility: hidden;
    animation: wobble 1500ms infinite;
  }

  @media (max-width: 440px) {
    &Closed {
      display: none;
    }
  }

  &__day {
    z-index: 1;
  }

  &__bg {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border-radius: 8px;
    background: #000;
    opacity: 0.1;
    z-index: 0;

    .doorOpen & {
      background: color("accent");
      opacity: 0.7;
    }
  }
}

.christmas {
  border: 2px dashed #fff;
  margin: size("gutter--small");
  height: $christmasHeight;
  width: $christmasWidth;
}

.easter {
  height: $easterHeight;
  width: $easterWidth;

  @media (max-width: 600px) {
    height: $easterHeight - 50px;
    width: $easterWidth - 50px;
  }

  @media (max-width: 440px) {
    height: $easterHeight - 90px;
    width: $easterWidth - 90px;
  }
}