@use "sass:list";

@import "../ui-components/_variables.scss";
@import "../ui-components/_mixins.scss";

/*
  Global typography rules
*/

$block: "typography";

@mixin headings($sizes...) {
  $uniqueId: unique-id();

  %#{$uniqueId} {
    @content;
  }

  @each $size in $sizes {
    h#{$size},
    .h#{$size} {
      @extend %#{$uniqueId};
    }
  }
}

:global {
  @include headings(1, 2, 3, 4, 5, 6) {
    margin: 0;
    font: 700 list.slash(1em, font("line-height--header")) font("header"), font("fallback");
    @include vertical-margins(size("gutter--large"));
  }

  .h1, h1 {
    line-height: 1;
    font-size: size("h1");
    font-weight: 900;

    @media (max-width: breakpoints("small") - 1px) {
      font-size: size("h1") * .75;
    }
  }

  h2 {
    font-size: size("h2");
  }

  h3 {
    font-size: size("h3");
  }

  h4 {
    font-size: size("h4");
    margin-bottom: size("gutter");
  }

  h5 {
    font-size: size("h5");
  }

  h6 {
    font-size: size("h6");
  }

  %anchor {
    border-bottom: 1px solid color("border");
    padding-bottom: 1px;

    &:focus,
    &:hover {
      color: color("accent");
      border-bottom-color: currentColor;
    }
  }

  p,
  .paragraph {
    line-height: font("line-height");
    @include vertical-margins(size("gutter"));

    &,
    > i,
    > b,
    > em,
    > strong {
      > a {
        @extend %anchor;
      }
    }
  }

  .lead {
    font-size: font("size--large");
  }

  .anchor {
    @extend %anchor;
  }

  mark {
    padding: .2em;
    background: color("mark");
  }

  strong, b {
    font-weight: 700;
  }

  .#{$block} {
    line-height: font("line-height");

    ol, ul {
      line-height: font("line-height");
      padding-left: size("gutter--small");
      @include vertical-margins(size("gutter--small"));

      ul {
        list-style-type: circle;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: none; /* [1] */
    }

    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      line-height: font("line-height");

      + p {
        margin-top: 0;
      }
    }

    table {
      @include vertical-margins(size("gutter--small"));
    }

    td,
    th {
      text-align: left;
      vertical-align: baseline;
      line-height: font("line-height--header");
    }

    tr {
      &:not(:first-child) {
        td, th {
          padding-top: .5em;
        }
      }

      &:not(:last-child) {
        td, th {
          padding-bottom: .5em;
        }
      }
    }


    td:not(:first-child) {
      padding-left: size("gutter--small");
    }

    [style*="font-size"] {
      font-size: inherit !important;
    }
  }
}
