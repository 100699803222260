@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid color("border");
  color: color("text--muted");
  font-size: size("font--tiny");
  padding: size("gutter--small") 0;
  padding-bottom: 4.75rem;
  content-visibility: auto;

  @media (max-width: breakpoint("tiny")) {
    flex-direction: column;
    gap: size("gutter--small");

    svg {
      padding-left: size("gutter--tiny");
      margin-top: 0 !important;
    }
  }

  .cookieLink {
    display: flex;
    justify-content: end;
  }


  a {
    color: color("text--muted");

    &:hover {
      color: color("accent");
    }
  }

  & > * {
    flex: 1 1 auto;
    margin-top: 0;
    margin-bottom: 0;
  }

  svg {
    flex: 0 0 auto;
    width: 100px;
    height: 70px;

    @media (max-width: breakpoint("tiny")) {
      margin-top: size("gutter--small");
    }
  }
}
