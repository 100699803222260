@import "theme/ui-components/_variables.scss";

$burgerHeight:  3px;
$burgerWidth:   1.25em;
$burgerSpacing: 1.25;

$burgerColor: color("text");

.block {
  min-height: ceil(($burgerHeight * 5) + ($burgerSpacing * 2));

  &:hover {
    .icon {
      &,
      &::before,
      &::after {
        will-change: transform, background, top;
      }
    }
  }

  &:hover, &:active {
    color: $burgerColor !important;
  }

  &:active:hover {
    opacity: .5;
  }

  &.isActive .icon {
    background: transparent !important;

    &::before,
    &::after {
      top: 0;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
      backface-visibility: hidden;
      top: -$burgerHeight;
    }
  }
}

.icon,
.icon::before,
.icon::after {
  display: block;
  width: $burgerWidth;
  height: $burgerHeight;
  background: $burgerColor;
  border-radius: $burgerHeight;
  transition-property: transform, background;
  transition-duration: transition("duration--atoms");
  transition-timing-function: transition("ease-out");
}

.icon::before,
.icon::after {
  content: "";
  margin: 0 auto;
}

.icon::before { top: -#{round(($burgerHeight * $burgerSpacing) + ($burgerHeight))}; }
.icon::after  { top:     round($burgerHeight * $burgerSpacing); }

.contain {
  display: flex;
  align-items: center;
}

.text {
  margin-left: size("gutter--tiny");
  font-size: size("font--small");
  font-weight: 700;
  text-transform: uppercase;
  color: $burgerColor !important;

  // Offset font
  top: 1px;
}
