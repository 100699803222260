@import "../../node_modules/@crossroads/ui-components/src/ui-components/AdventCalendar.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
$paddingX: 10px;
$paddingY: 5px;

.door {
  &Text {
    h3, h2 {
      margin-top: 0;
      margin-bottom: .5rem;
    }

    padding: $paddingY $paddingX;
  }
}
