@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$max-item-width: 15.5rem;;

.block {
  display: flex;
  justify-content: center;

  @media (max-width: breakpoint("small") - 1px) {
    flex-direction: column;
    align-items: center;
  }
}

.description {
  font-size: size("font--small");
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &Inner {
    padding: 0 size("gutter");
  }

  h2 {
    font-weight: 900;
    font-size: 2rem;
    margin: 0;
  }

  .block__left & {
    text-align: right;
  }

  @media (max-width: breakpoint("small") - 1px) {
    &Inner {
        & > * {
          text-align: center;
        }
    }
  }
}

.container {
  margin: 0 -#{size("gutter--small") * .5};
  width: calc(100% + #{size("gutter--small")});
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: breakpoint("small") - 1px) {
    flex-direction: column;
  }

  @media (min-width: breakpoint("small")) {
    .block__right & {
      flex-direction: row-reverse;
    }
  }

  & > * {
    flex: 0 0 calc(25% - #{size("gutter--small")});
    margin: 0 #{size("gutter--small") * .5};
    max-width: $max-item-width;

    @media (max-width: breakpoint("large") - 1px) {
      flex: 0 0 calc(100% / 3 - #{size("gutter--small")});

      &:nth-child(1n + 4) {
        display: none;
      }
    }

    @media (max-width: breakpoint("medium") - 1px) {
      flex: 0 0 calc(50% - #{#{size("gutter--small")}});

      &:nth-child(1n + 3) {
        display: none;
      }
    }

    @media (max-width: breakpoint("small") - 1px) {
      flex: 0 0 100%;
      max-width: none;
    }
  }
}

.button{
  &Bottom {
    margin-top: size("gutter");
    font-weight: 400;
    font-size: size("font--small");

    @media (min-width: breakpoint("small")) {
      display: none;
    }
  }

  &Top {
    font-weight: 400;
    font-size: size("font--small");

    @media (max-width: breakpoint("small") - 1px) {
      display: none;
    }
  }
}
