/*
  Styles for the image slider
*/

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";


// local variables:
// ----------------

  $barWidth         : 3rem;
  $barWidth--small  : 2rem;
  $bodyWidth        : 20rem + $barWidth;
  $bodyWidth--small : 15rem + $barWidth;
  $inset            : size("gutter");
  $slideDuration    : 600ms;
  $barDuration      : 800ms;
  $stutterDuration  : 120ms;
  $dotLength        : .8rem;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(size("gutter"));
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

// ----------------


.slider {
  overflow: hidden;
}

.list {
  display: flex;
  overflow: hidden;
  transition: transform $slideDuration ease;
}

.item {
  display: flex;
  float: left;
  overflow: hidden;
  background-color: color("primary");

  @media (max-width: breakpoint("medium") - 1px) {
    flex-direction: column;
  }

  @media (min-width: breakpoint("medium")) {
    height: 360px;
    max-height: 80vh;
  }
}

.bar {
  background-color: color("primary--dark");
  transition: transform $barDuration transition("swift-out");

  @media (max-width: breakpoint("medium") - 1px) {
    height: 100vh;
    width: $barWidth--small;
    position: absolute;
    bottom: 100%;
    left: 100%;
  }

  @media (min-width: breakpoint("medium")) {
    width: $barWidth;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.media {
  display: flex;
  overflow: hidden;
  flex: 1 1 auto;
  transition: transform $slideDuration linear;
  background-color: black;

  &.isMoving {
    transform: translateX(50%);
  }

  @media (max-width: breakpoint("medium") - 1px) {
    max-height: 70vh;
  }
}

.text {
  color: white;
  text-shadow: 0 1px 3px rgba(black, .2);
  transition: opacity transition("duration--long");

  @media (max-width: breakpoint("large") - 1px) {
    font-size: size("font--small");
  }

  &.isHidden {
    opacity: 0;
  }
}

.row {
  opacity: 0;

  &:nth-child(2)   { animation-delay: $stutterDuration * 1; }
  &:nth-child(3)   { animation-delay: $stutterDuration * 2; }
  &:nth-child(4)   { animation-delay: $stutterDuration * 3; }
  &:nth-child(n+5) { animation-delay: $stutterDuration * 4; }

  .list:not(.isMoving) .text.isCurrent > & {
    animation-name: fadeIn;
    animation-duration: $slideDuration;
    animation-fill-mode: forwards;
  }
}

.image {
  width: 100%;

  @media (max-width: breakpoint("medium") - 1px) {
    display: block;
  }

  img {
    @media (max-width: breakpoint("medium") - 1px) {
      max-width: 100%;
    }
  }
}

.body {
  order: 2;
  padding: $inset;
  text-align: right;
  z-index: 1;

  @media (min-width: breakpoint("medium")) {
    float: right;
    width: $bodyWidth--small;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 auto;
    padding-top:    size("gutter--huge");
    padding-bottom: size("gutter--huge");
    padding-left:   $inset + $barWidth;
  }

  @media (min-width: breakpoint("large")) {
    width: $bodyWidth;
  }
}

.dots {
  overflow: hidden;
  padding: size("gutter--small");
  position: absolute;
  left: 0;

  @media (max-width: breakpoint("medium") - 1px) {
    top: 0;
  }

  @media (min-width: breakpoint("medium")) {
    bottom: 0;
    padding: size("gutter");
  }
}

.dot {
  float: left;

  & + & {
    margin-left: size("gutter--small");

    @media (min-width: breakpoint("medium")) {
      margin-left: size("gutter");
    }
  }
}

.dotButton {
  border: none;
  border-radius: 50%;
  width:  $dotLength;
  height: $dotLength;
  background-color: #9e9e9e;
  box-shadow: bottom-shadow(1);

  &.isActive {
    background-color: white;
  }

  &:active:hover {
    box-shadow: 0 0 1px color("grey", 600), inset 0 0 0 $dotLength rgba(black, .1);
  }
}

.heading {
  font-size: size("h1") - .5em;
}
