@use "sass:list";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$headerHeight: size("header-height");
$miniCartMaxHeight: 555px;
$miniCartWidth: 312px;
$miniHamburgerWidth: 25px;
$lineHeight: size("ui-border");
$lineWidth: 30%;
$lineWidth--small: 6rem;
$dotLength: 2px;

:export {
  headerHeight: strip-unit(size("header-height"));
}


@keyframes slideDown {
  0% { opacity: 0; transform: translateY(0); }
  100% { opacity: 1; transform: translateY($headerHeight); }
}

@keyframes fadeInSearch {
  0% { opacity: .7; }
  100% { opacity: 1; }
}

@keyframes fadeInBackButton {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.block {
  background: #fff;
  z-index: z-index("system") + 2;
  position: absolute;
  width: 100%;
  transition: top transition("duration--organisms") transition("ease-ease");
  will-change: top;
  top: 0;

  &.active {
    box-shadow: -1px 3px 15px 0 rgba(141,151,166,.16);
  }

  &.hidden {
    top: calc(-#{size("header-height")} - #{$lineHeight});
  }

  &::before {
    content: "";
    display: block;
    height: $lineHeight;
    z-index: 1;
    background: linear-gradient(
      to right,
      color("primary")       50%,
      color("primary--dark") 50%,
      color("primary--dark") 100%
    ) 100% list.slash(0, $lineWidth--small) 100% no-repeat color("accent");

    @media (min-width: breakpoint("small")) {
      background-size: $lineWidth 100%;
    }
  }
}

.hide {
  @media (max-width: breakpoint("small")) {
    display: none;
  }
}

.wrapper {
  height: size("header-height");
  flex: 1 0 100%;


  @media (max-width: (breakpoint("tiny") - 1px)) {
    padding-left: size("gutter--small");
    padding-right: size("gutter--small");
  }
}

.wrapperInner {
  background: #fff;
  border-bottom: $lineHeight solid color("border");
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.left, .right {
  display: flex;
  align-items: stretch;
}

.left {
  flex: 1 1 auto;
}

.right {
  justify-content: flex-end;
  flex: 0 0 auto;

  .item {
    padding: 0 size("gutter--mini");
  }
}

.item__search {
  display: flex !important;
  flex: 1 0 auto;
  align-items: center;
  animation:  fadeInSearch transition("duration--organisms") transition("ease-out") forwards;
}

.search + .left {
  order: -1;
}

.mobile {
   &.search + .left + .right {
    display: none;
  }

   &.search + .left {
    display: none;
  }
}

.nav {
  display: flex;
  align-items: stretch;

  svg {
    width: 20px;
    height: 20px;
  }
}

.links {
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
}

.collapsableNav {
  flex: 1 1 auto;
  display: flex;
  width: 0;
  margin-left: 1rem;

  nav {
    display: flex;
    & > * {
      display: flex !important;
      white-space: nowrap;

      &:nth-child(1n + 2) {
        padding-left: 1.5rem;
      }
    }
  }
}

.item {
  font-size: size("font--small");
  color: color("text--muted");
  text-decoration: none;
  cursor: pointer;
  font-weight: normal;
  user-select: none;
  display: flex;
  align-items: center;

  &:hover, &:focus {
    color: color("primary");
    text-decoration: none;
  }

  &:active:hover {
    opacity: .5;
  }

  &.active {
    color: color("primary");

    &:before {
      display: block;
      content: "";
      height: .15rem;
      background-color: #ea1c0a;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
    }
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    &:not(.hamburger) {
      display: none !important;
    }
  }
}

.item__toggle {
  white-space: nowrap;

  & > * {
    height: 100%;
  }

  svg {
    width: 10px;
    height: 10px;

    top: 1px;

    flex: 1 0 auto;
    transform: rotate(-90deg);
    margin-left: size("gutter--mini");
  }

  &.open svg {
    transform: rotate(90deg);
  }
}

.item__home {
  flex: 0 0 auto;
  padding: 0;
  margin-right: 1rem;


  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: color("text");
  }

  &::after {
    content: "";
    display: block;
    width: $dotLength;
    height: $dotLength;
    border-radius: 50%;
    background-color: color("text");
    margin-top: -($dotLength * 0.5);
    position: absolute;
    top: 50%;
    right: -#{size("gutter--small")};
  }
}

.item__logo {
  margin-left: size("gutter--large");
}

.back {
  animation: fadeInBackButton transition("duration--templates") transition("ease-out") forwards;
}

.back {
  text-transform: uppercase;
  font-weight: 700;
}

.search {
  @media (min-width: breakpoint("small")) {
    background: #fff;
    position: fixed !important;
    left: 0;
    right: 0;
    z-index: z-index("system") + 1;
    animation:  slideDown transition("duration--organisms") transition("ease-out") forwards;
  }
}

.desktop.login {
  position: absolute;
  left: auto;
  right: 0;
  bottom: auto;
  top: 100%;
  width: 390px;
  border-bottom-left-radius: size("border-radius");
  border-bottom-right-radius: size("border-radius");
  animation:  fadeInSearch transition("duration--organisms") transition("ease-out") forwards;
}

.dim {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("overlay") + 1;

  background-color: rgba(0,0,0,.5);
}

.languageChevron {
  transform: rotate(-90deg);
  margin-left: size("gutter--mini");
}

.languageSwitcher {
  position: absolute;
  left: 0;
  overflow: hidden;
  border-bottom-left-radius: size("border-radius");
  border-bottom-right-radius: size("border-radius");
  font-size: size("font");
  min-width: 200px;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.2);
}

.cartMini {
  position: absolute;
  top: size("header-height");
  left: auto;
  right: size("gutter--large");
  max-height: $miniCartMaxHeight;
  width: $miniCartWidth;
  z-index: z-index("overlay");
  box-shadow: 0 24px 64px 0 rgba(0, 0, 0, 0.1);
  border-radius: size("border-radius");
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.hamburger {
  min-width: $miniHamburgerWidth;
  margin: 0 -0.8rem;

  @media (min-width: breakpoint("small")) {
    display: none !important;
  }
}

.desktopNavHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height");
  border-bottom: 1px solid color("border--muted");

  h3 {
    margin-bottom: 0;
  }
}

.subNav {
  background: color("OOC--color");

  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  width: 100%;

  @media (max-width: (breakpoint("small") - 1px)) {
    min-height: 100vh;
  }

  display: none;

  @media (max-width: breakpoint("small")) {
    left: -100%;
  }
}

.desktop {
  @media (max-width: (breakpoint("small") - 1px)) {
    display: none !important;
  }
}

.mobile {
  @media (min-width: breakpoint("small")) {
    display: none !important;
  }
}

:not(.mobile).subNav.search {
  position: absolute;
  @media (min-width: breakpoint("small")) {
    top: size("ui-border");
  }


  display: flex;
  flex: 1 1 auto;
  align-items: center;
  background: none;
}

.mode__search .search { display: block; }

.hiddenCategories {
  background: #fff;

  position: absolute;
  top: calc(#{$headerHeight} + .15rem);
  left: 0;
  right: 0;

  padding: size("gutter--large") 0;
  border-bottom: size("ui-border") solid color("accent");

  a + a {
    margin-top: size("gutter--small");
  }

  a {
    display: block;
    color: color("text");

    &.active {
      color: color("primary") !important;
    }

    svg {
      width: size("gutter--small");
      height: size("gutter--small");
      margin-right: size("gutter--small");
      top: -.1rem;
      transition: transform transition("duration--molecules") transition("ease-ease");
    }

    &:hover {
      color: color("accent");
      text-decoration: none;

      svg {
        transform: translateX(.5rem);
      }
    }
  }
}

.visible:not(#foo).item__home {
  display: flex !important;

  &::after {
    display: none;
  }
}
