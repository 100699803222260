@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.filterbar {
  z-index: z-index("popup");
}

.filterBarHint {
  height: 89px;
}

.filterButton, .sortButton {
  color: color("text") !important;
  border-color: color("border") !important;
  flex: 1;
  font-weight: 700 !important;
  text-transform: none !important;
}

.filterIcon {
  height: 20px;
}

.sortIcon {
  height: 16px;
}
